.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 0.4rem;
  box-sizing: border-box;
  margin: 0;
  margin-top: 1.2rem;
}

.text-container {
  background-color: grey;
  background-color: rgba(242, 202, 170, 0.94);
  /* background-image: linear-gradient(
      165deg,
      #592b22 0%,
      #321a1a 34%,
      #1d0808 66%,
      #321a1a 94%
    ); */

  width: 95vw;
  max-width: 1000px;
  box-sizing: "border-box";
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  /* padding-bottom: 0; */
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.text {
  font-family: "Martel", serif;
  color: white;
  color: black;
  width: 100%;
  line-height: 1.4;
  font-size: 1.4rem;
  display: block;
}

@media screen and (max-width: 600px) {
  .text-container {
    padding: 0.4rem;
  }

  .text {
    font-size: 1rem;
    line-height: 1.75;
    /* padding: 0.5rem; */
  }
}
