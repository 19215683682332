.sign-in-bg {
  /* background: linear-gradient(0deg, #95ca95 20%, #606065); */
  display: flex;
  /* margin-top: 2rem; */
  /* margin-bottom: 2rem; */
  /* justify-content: center; */
  /* align-items: center; */
  /* height: 100vh; */
  /* max-height: 850px; */
  box-sizing: border-box;
  /* background-color: #302a2a; */
}

.wrapper {
  width: 100vw;
  margin: 0;
  margin-top: 0;
  margin-bottom: 1.2rem;
  padding: 0.4rem;
  padding-top: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form-container {
  display: inline-flex;
  flex-direction: column;
  display: inline-block;
  /* background-color: #1c1c1c; */
  /* background-color: #727272;
  background-color: #302a2a; */
  /* background-color: #180703; */
  /* background-color: rgba(242, 202, 170, 0.94); */
  width: 350px;
  /* padding: 2rem; */
  /* padding: 1rem; */
  padding-top: 0;
  /* margin-bottom: 30vh; */
  margin-top: 0;
  border-radius: 0.3rem;
  box-sizing: border-box;
}

form {
  width: 350px;
  box-sizing: border-box;
}

/* .submit-button {
  width: 100%;
  height: 4rem;
  background: #44c4e7;
  color: #fff;
  color: rgb(12, 11, 11);

  margin-top: 1.2rem;
  border: solid 0.1rem grey;
  border-radius: 0.3rem;
  font-size: 1rem;
  text-align: center;
  font-weight: 600;
  text-transform: uppercase;

  vertical-align: middle;
  margin-left: 0 auto;
  box-sizing: border-box;
}

.submit-button:hover {
  background: #fbabff;
  color: black;
} */

.submit-button {
  display: inline-block;
  height: 4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem;
  box-sizing: border-box;
  border: 1px solid rgb(187, 237, 180);
  border-radius: 0.3rem;
  /* transition: all 0.2s ease-in-out; */
  font-weight: 600;
  background-color: rgba(22, 118, 22);
  color: white;
  width: 100%;
}

.submit-button:hover {
  background-color: rgb(22, 118, 22);
  border: 3px solid rgb(135, 249, 78);
  box-shadow: 0px 15px 30px rgba(2, 43, 3, 0.6);
  color: white;
}

.grow {
  transition: all 12.2s ease-in-out;
}

@media screen and (max-width: 600px) {
  .submit-button {
    padding: 1rem;
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
    width: 10rem;
    height: 5rem;
  }
}
