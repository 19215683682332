.recording-intro-text {
  padding: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.2rem;
}

.record-button {
  background-color: rgb(249, 104, 104);
}

.record-button:hover {
  background-color: lightcoral;
  border: 3px solid rgb(226, 25, 25);
  box-shadow: 0px 15px 20px rgba(203, 19, 6, 0.4);
}
