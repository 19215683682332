.plot-container {
  width: 16rem;
  height: 12rem;
  display: inline-block;
  background-color: burlywood;
  margin: 1.2rem;
  border-radius: 0.2rem;
  padding: 0.3rem;
}

.plot-canvas {
  width: 100%;
  height: 100%;
}

.plot-controls {
  background-color: black;
  width: 15.4rem;
  height: 7rem;
  color: white;
}
