.selection-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 0.4rem;
  box-sizing: border-box;
  margin: 0;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  background-color: rgba(235, 197, 166, 0.248);
}
