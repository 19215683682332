.wrapper {
  width: 100vw;
  margin: 0;
  margin-top: 0;
  margin-bottom: 1.2rem;
  padding: 0.4rem;
  padding-top: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.upload-landing-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  /* background-image: linear-gradient(
    165deg,
    #592b22 0%,
    #321a1a 34%,
    #1d0808 66%,
    #321a1a 94%
  ); */
  /* background-color: #220f0b; */
  background-color: rgba(242, 202, 170, 0.94);
  width: 95vw;
  max-width: 1000px;
  flex-direction: column;
  margin: 0;
  /* background-color: rgb(101, 98, 95); */
  padding: 5rem;
  padding-top: 0;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  border-radius: 0.3rem;
}

.welcome-user {
  font-family: "Martel", serif;
  font-size: 1.4rem;
  border-bottom: 1px solid white;
  /* border-radius: 0.6rem; */
  padding: 0rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  box-sizing: border-box;
  /* margin-top: 1.2rem; */
}

.explain-farming {
  margin-top: 0.6rem;
  width: 40vw;
  min-width: 15rem;
}

/* .upload-landing-name {
  color: rgb(133, 254, 122);
  font-size: 1rem;
} */

.upload-landing-text {
  font-family: "Martel", serif;
  color: black;
  font-size: 1.2rem;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
}

.record {
  background-color: rgba(186, 62, 62, 0.933);
  border: 3px solid lightcoral;
  margin-bottom: 1.2rem;
}

.record:hover {
  background-color: lightcoral;
  border: 3px solid rgb(226, 25, 25);
  box-shadow: 0px 15px 20px rgba(203, 19, 6, 0.4);
  /* transform: translateY(-0.1rem); */
}

.upload {
  background-color: rgba(46, 133, 46, 0.967);
  /* background-image: linear-gradient(
    165deg,
    #82e47d 0%,
    #321a1a 34%,
    #1d0808 66%,
    #321a1a 94%
  ); */
  border: 3px solid rgb(73, 172, 67);
  margin-right: 0.6rem;
  margin-bottom: 0.8rem;
}

.upload:hover {
  background-color: rgb(109, 191, 109);
  border: 3px solid rgb(32, 223, 21);
  box-shadow: 0px 15px 20px rgba(108, 169, 123, 0.4);
  /* transform: translateY(-0.1rem); */
}

@media screen and (max-width: 600px) {
  .upload-landing-text {
    font-size: 1rem;
  }
}
