.drop-zone {
  width: 35vw;
  min-width: 14rem;
  max-width: 26rem;
  /* height: 30vh; */
  /* background-color: blue; */
  border: dashed 3px rgb(109, 56, 15);
  border-radius: 0.3rem;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.drop-heading {
  color: rgb(101, 92, 92);
  margin-bottom: 0;
  width: 100%;
}
