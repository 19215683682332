.names-container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: -100;
  background-color: transparent;
}

.sound-info-container {
  position: relative;
  height: 5rem;
  z-index: -2;
  background-color: transparent;
  padding-left: 2rem;
}

.sound-title {
  color: rgba(220, 200, 220, 0.5);
  z-index: -4;
  width: 90vw;
  background-color: transparent;
}
