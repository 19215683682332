.upload-sound-container {
  width: 55vw;
  min-width: 30rem;

  margin: 0;
  background-color: #d2af91;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.3rem;
}

.upload-banner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgb(113, 107, 107);
  background-color: #d2af91;

  flex-direction: column;
  margin: 0;
  /* background-color: rgb(101, 98, 95); */
  padding-bottom: 0.6rem;
  padding-top: 0;
  margin-bottom: 0;
  border-radius: 0.3rem;
}

.upload-heading {
  margin-bottom: 0.3rem;
  margin-top: 0;
  text-align: center;
  width: 100%;
}

.upload-image {
  width: 100px;
  height: 100px;
}

.upload-file-text {
  color: white;
  font-size: 0.8rem;
  margin-bottom: 1.6rem;
}

.upload-file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45vw;
  min-width: 16rem;
  max-width: 30rem;
  margin-bottom: 3rem;
  /* background-color: rgb(101, 98, 95); */
  background-color: #d2af91;
  padding: 1.2rem;
  border-radius: 0.3rem;
}

.file-to-upload-container {
  background-color: #d2af91;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attached-image {
  width: 100px;
  height: 100px;
}

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d2af91;
}

.file-description {
  /* width: 40vw; */
}

.attach-file-button {
  background-color: #372b2b;
}

.file-input-container {
  display: flex;
}
.cancel-button {
  background-color: rgb(211, 94, 94);
  margin-bottom: 1.2rem;
}

.cancel-button:hover {
  background-color: rgb(210, 41, 41);
  border: 3px solid rgb(225, 149, 143);
  box-shadow: 0px 15px 20px rgba(179, 6, 3, 0.4);
}

.retry-button {
  background-color: rgb(96, 94, 211);
  margin-bottom: 1.2rem;
}

.retry-button:hover {
  background-color: rgb(44, 41, 210);
  border: 3px solid rgb(147, 143, 225);
  box-shadow: 0px 15px 20px rgba(56, 3, 179, 0.4);
}

.waveform-canvas {
  /* width: 100%;
  height: 100px; */
  /* margin-bottom: 5rem; */
  margin-top: 1.2rem;
  border-radius: 0.6rem;
  border: solid 2px rgba(16, 206, 22, 0.4);
  z-index: 100;
}

.fail-container {
  padding: 2rem;
}

#file-description {
  width: 100%;
}

.submit-button {
  display: inline-block;
  height: 4rem;
  margin-top: 0.2rem;
  margin-bottom: 0.6rem;
  box-sizing: border-box;
  border: 1px solid rgb(187, 237, 180);
  border-radius: 0.3rem;
  /* transition: all 0.2s ease-in-out; */
  font-weight: 600;
  background-color: rgba(22, 118, 22);
  color: white;
  width: 100%;
  text-transform: uppercase;
  cursor: pointer;
}

.submit-button:hover {
  background-color: rgb(22, 118, 22);
  border: 3px solid rgb(135, 249, 78);
  box-shadow: 0px 15px 30px rgba(2, 43, 3, 0.6);
  color: white;
}

.grow {
  transition: all 12.2s ease-in-out;
}

@media screen and (max-width: 600px) {
  .submit-button {
    padding: 1rem;
    font-size: 0.8rem;
    margin-bottom: 0.6rem;
    width: 10rem;
    height: 5rem;
  }
}
