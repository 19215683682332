.item-controls {
  background-color: rgba(100, 100, 100, 0.1);
  width: 40%;
  max-width: 8rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.3rem;
  padding: 0.5rem;
  min-height: 350px;
  border: 6px solid red;
  position: relative;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  .item-controls {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
}

.voice-title {
  margin: 0;
  margin-bottom: 0.5rem;
  color: white;
}

.paused {
  background-color: rgba(45, 24, 24, 0.1);
  border: 1px solid rgb(150, 148, 148);
}

.item-slider-item {
  width: 100%;
}
