/* className="rounded-md w-full border border-gray-400 p-3 mb-5" */

.input-container {
  width: 100%;
  /* background-color: rgba(242, 202, 170, 0.94); */
}

.text-input {
  width: 100%;
  max-width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
  padding: 0.6rem;
  margin-bottom: 0.6rem;
  border: 1px solid rgb(187, 237, 180);
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-left: 0 auto;
}

.text-input-no-error {
  border: solid 0.1rem grey;
  color: black;
}

.text-input-error {
  border: solid 0.1rem red;
  color: red;
}

.error-text {
  color: #ff0000;
}
