.about-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 0.4rem;
  box-sizing: border-box;
  margin: 0;
  margin-top: 1.2rem;
}

.about-flex {
  background-color: rgba(242, 202, 170, 0.94);
  /* background-image: linear-gradient(
      165deg,
      #592b22 0%,
      #321a1a 34%,
      #1d0808 66%,
      #321a1a 94%
    ); */

  width: 95vw;
  /* max-width: 1000px; */
  box-sizing: "border-box";
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  /* padding-bottom: 0; */
  border-radius: 0.3rem;
  font-size: 1rem;
  margin-bottom: 2rem;
}

.text-container {
  border-bottom: 1px solid black;
  border-radius: 0;
  /* background-color: rgba(242, 202, 170, 0.94); */
  background-color: transparent;
  width: 80vw;
  max-width: 50rem;
  padding: 1rem;
  line-height: 1.4;
  font-size: 1.4rem;
  vertical-align: baseline;
}

.about-link {
  background-color: rgba(83, 83, 191, 0.01);
  /* border: 1px solid rgb(110, 120, 250); */
  color: white;
  text-decoration: none;
  padding: 0.2rem;
  border-radius: 0.3rem;
  line-height: 1.4;
  font-size: 1.4rem;
  height: 1.4rem;
  margin-bottom: 0.4rem;
}

.about-link:hover {
  color: rgb(184, 116, 218);
  background-color: rgba(218, 218, 232, 0.01);
}

.hand-wave {
  width: 5rem;
  height: 5rem;
  font-size: 5rem;
  margin-bottom: 2rem;
}

@media screen and (max-width: 600px) {
  .text-container {
    padding: 0.1rem;
  }

  .about-link {
    font-size: 1rem;
  }

  .text {
    font-size: 1rem;
    line-height: 1;
    /* padding: 0.5rem; */
  }
  .hand-wave {
    width: 3rem;
    height: 3rem;
    font-size: 3rem;
  }
}
