.add-sound-container {
  background-color: rgba(100, 100, 100, 0.1);
  width: 40%;
  max-width: 8rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.3rem;
  padding: 0.5rem;
  min-height: 350px;
  border: 2px solid rgb(150, 238, 126);
  position: relative;
  box-sizing: border-box;
}

@media screen and (max-width: 600px) {
  .item-controls {
    width: 100%;
    max-width: 100%;
    margin: 0;
    margin-bottom: 1rem;
  }
}

.add-sound-info {
  margin: 0;
  width: 100%;
  margin-bottom: 0.5rem;
  color: white;
  text-align: center;
}

.add-sound-button {
  width: 40%;
  color: rgb(0, 0, 0);
  background-color: rgb(43, 113, 23);
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid white;
  font-size: 2rem;
}

.add-sound-button:hover {
  background-color: rgb(180, 240, 163);
  color: rgb(13, 58, 9);
}

.paused {
  background-color: rgba(45, 24, 24, 0.1);
  border: 1px solid rgb(150, 148, 148);
}

.item-slider-item {
  width: 100%;
}
