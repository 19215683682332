.center-panel {
  display: flex;
  flex-direction: column;
  /* justify-content: top; */
  /* align-items: center; */
  color: #ececec;
  margin-bottom: 1rem;
  width: 350px;
  /* padding: 1rem; */
  box-sizing: border-box;
  font-size: 0.8rem;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  /* background-image: linear-gradient(
      165deg,
      #592b22 0%,
      #321a1a 34%,
      #1d0808 66%,
      #321a1a 94%
    ); */
  /* background-color: #220f0b; */
  background-color: rgba(242, 202, 170, 0.94);
  width: 95vw;
  max-width: 1000px;
  flex-direction: column;
  margin: 0;
  /* background-color: rgb(101, 98, 95); */
  padding: 5rem;
  padding-top: 0;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  border-radius: 0.3rem;
}

.login-form-container {
  background-color: none;
}

.upload-text {
  width: 350px;
  margin: 0 auto;
  text-align: center;
  /* background-color: rgb(201, 143, 226); */
  color: rgb(218, 216, 216);
  color: black;
  box-sizing: border-box;
  margin-top: 2rem;
  margin-bottom: 2rem;
  /* border: 1px solid white; */
  border-radius: 0.3rem;
}

.message {
  /* text-align: left; */
  padding: 1rem;
  /* margin: 1rem; */
  /* padding-left: 4rem;
    padding-right: 4rem; */
  width: 350px;
  margin: 0;

  box-sizing: border-box;
}

.back-button {
  text-decoration: none;
  color: #ececec;
  background-color: #850c0c6b;
  padding: 0.6rem;
  padding-top: 1.4rem;
  padding-bottom: 1.4rem;
  display: inline-block;
  border-radius: 0.3rem;
  border: 1px solid #ececec;
  /* margin-left: 1.2rem;
    margin-right: 1.2rem; */
  margin: 0 auto;
  /* margin-bottom: -2rem; */
  margin-top: 2rem;
  box-sizing: border-box;
  width: 350px;
  color: rgb(255, 255, 255);
  font-weight: 600;

  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease 0s;
  background-color: rgb(211, 94, 94);
  margin-bottom: 1.2rem;
}

/* .cancel-button {
  background-color: rgb(211, 94, 94);
  margin-bottom: 1.2rem;
} */

.back-button:hover {
  background-color: rgb(210, 41, 41);
  border: 3px solid rgb(225, 149, 143);
  box-shadow: 0px 15px 20px rgba(179, 6, 3, 0.4);
}

/* 
.back-button:hover {
  color: rgb(32, 24, 72);
  background-color: #ececec;
} */

.white {
  color: white;
}

@media screen and (max-width: 600px) {
  .upload-text {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .back-button {
    margin-top: 0.5rem;
  }
}
