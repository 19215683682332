.theme-controls-container {
  /* background-color: rgba(242, 202, 170, 0.94); */
  width: 50vw;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */
  /* width: 100vw; */
  /* padding: 0.4rem;
  padding-left: 0.6rem; */
  box-sizing: border-box;
  margin: 0 auto;
  /* margin-right: 4rem; */
  border-right: solid 3px rgb(193, 169, 141);
}

.style-text-block {
  width: 6rem;
  background-color: transparent;
  /* height: 4rem; */

  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: none;
  color: black;
  font-weight: 600;
  font-size: 0.5rem;
  text-transform: uppercase;
  /* box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease 0s;
}

.theme-button {
  width: 5rem;
  margin: 0.25rem;
  /* height: 4rem; */
  margin-top: 0;
  /* margin-left: 0.25rem; */
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 2px solid rgb(135, 230, 187);
  /* border: 2px solid rgb(193, 169, 141) */
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 0.5rem;
  text-transform: uppercase;
  /* box-shadow: 0px 15px 30px rgba(51, 53, 50, 0.283); */
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease 0s;
}

.poetic-green {
  background-color: #60a70f;
}

.textual-green {
  background-color: #25772f;
}

.hand-green {
  background-color: #51bb5e;
}
.original-green {
  background-color: #6daf75;
}

.deselected-color {
  background-color: #acb1ac;
}

/* .deselected-textual-green {
}
.deselected-hand-green {
  background-color: #acb1ac;
}
.deselected-original-green {
  background-color: #8c9d8e;
} */
