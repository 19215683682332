.pause-button {
  background-color: rgba(252, 134, 134, 0.8);
  width: 2rem;
  height: 1.5rem;
  display: inline-block;
  position: absolute;
  top: 2%;
  left: 70%;
  border-radius: 0.2rem;
  border: 1px solid red;
}

.hover-bg {
  background-color: rgb(161, 249, 243);
}

.paused-bg {
  background-color: rgb(150, 148, 148, 0.8);
}
