.upload-bg {
  display: flex;
  justify-content: center;
  background-color: #2e180c;
  background-image: linear-gradient(
    165deg,
    #351812 0%,
    #321a1a 34%,
    #1d0808 66%,
    #321a1a 94%
  );
  /* padding: 0.5rem; */
  /* margin-left: 2rem; */
}
