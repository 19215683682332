.modal-container {
  /* display: flex; */
  width: 45vw;
  min-width: 16rem;
  max-width: 30rem;
  flex-direction: column;
  margin: 0;
  background-color: rgb(101, 98, 95);
  background-color: #d2af91;
  padding-bottom: 0;
  padding-top: 1.6rem;
  margin-bottom: 0.6rem;
  border-radius: 0.3rem;
}

.select-file-header {
  margin-top: 0;
  margin-bottom: 0;
}

.file-input-container {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.get-file-container {
  padding-top: 1rem;
}

/* .file-name-container {
} */

.progress-bar {
  background-color: blueviolet;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.file-input {
  display: none;
}

.choose-file-button {
  width: 10rem;
  height: 4rem;
  /* margin-right: 1.6rem; */
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
  margin-top: 1rem;
  padding: 1.4rem;

  box-sizing: border-box;
  border-radius: 0.3rem;
  border: 1px solid grey;
  background-color: rgb(138, 90, 75);
  color: rgb(255, 255, 255);
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease 0s;
}

.choose-file-button:hover {
  background-color: rgb(52, 23, 14);
  border: 3px solid rgb(226, 161, 114);
  box-shadow: 0px 15px 20px rgba(55, 21, 2, 0.4);
}

.file-name-red-text {
  color: rgb(138, 9, 9);
  font-size: 1rem;
  margin-top: 1rem;
}

.file-name-white-text {
  color: rgb(240, 234, 234);
  font-size: 1rem;
  margin-bottom: 0.6rem;
}

.file-name-black-text {
  color: black;
  font-size: 1rem;
  margin-bottom: 0.6rem;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.2rem;
  padding-bottom: 1.2rem;
}

.check-file-button {
  background-color: rgb(165, 130, 70);
  margin-bottom: 1.2rem;
  box-sizing: border-box;
}

.check-file-button:hover {
  background-color: rgb(198, 178, 25);
  border: 3px solid rgb(235, 223, 154);
  box-shadow: 0px 15px 30px rgba(85, 62, 4, 0.6);
}
.upload-file-button {
  background-color: rgb(125, 215, 117);
  box-shadow: 0px 5px 10px rgba(16, 12, 3, 0.3);
}

.upload-file-button:hover {
  background-color: rgb(19, 135, 8);
  border: 3px solid rgb(172, 247, 172);
  box-shadow: 0px 15px 20px rgba(0, 1, 0, 0.6);
}
