.item-slider {
  width: 100%;
  background-color: grey;
  display: inline-block;

  /* border: 1px solid white; */
  /* input-range-rv flex-auto white */
}

.white {
  color: white;
}

.red {
  color: red;
}

.item-slider-label {
  color: white;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 1rem;
  height: 0.3rem;
  font-size: 0.5rem;

  /* f5 ttu tracked b w5 tc dib mr4 white */
}

.item-slider-thing {
  vertical-align: middle;
  /* background-color: transparent; */
  /* padding-top: 8px;
    padding-top: 0.5rem;
    padding-bottom: 8px;
    padding-bottom: 0.5rem; */
  color: red;
  background-color: grey;
  /* border: 1px solid black; */
  box-shadow: 0.1rem 0.1rem rgba(0, 0, 0, 0.3);
  width: 4rem;
  /* background-color: transparent; */
  -webkit-appearance: none;
}

.item-slider-thing::-webkit-slider-thumb {
  position: relative;
  width: 18px;
  width: 0.25rem;
  /* height: 0.5rem; */
  height: 0.75rem;
  cursor: pointer;
  /* margin-top: 0.2rem; */
  margin-top: -0.5rem;
  border-radius: 3px;
  background-color: black;
  /* border: 1px solid darkgrey; */
  -webkit-appearance: none;
}

.item-slider-thing:focus {
  outline: none;
}

.item-slider-container {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* flex mb5 mw7 center */
}
