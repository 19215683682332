.container {
  width: 100%;
  padding: 1rem;
  padding-top: 0rem;
  padding-bottom: 0.2rem;
  align-items: center;
  box-sizing: border-box;
}

.text {
  font-family: "Martel", serif;
  color: white;
  color: black;
  width: 100%;
  line-height: 1.4;
  font-size: 1.4rem;
  display: block;
  /* // wordSpacing: '0.1rem', */
}

.email {
  color: black;
  text-decoration: none;
}

.upload-link {
  background-color: rgba(83, 83, 191, 0.01);
  /* border: 1px solid rgb(110, 120, 250); */
  color: white;
  text-decoration: none;
  padding: 0.5rem;
  height: 1rem;
  border-radius: 0.3rem;
}

.upload-link:hover {
  color: rgb(184, 116, 218);
  background-color: rgba(218, 218, 232, 0.01);
}

@media screen and (max-width: 600px) {
  .container {
    padding: 0.4rem;
  }

  .text {
    font-size: 1rem;
    line-height: 1.75;
    /* padding: 0.5rem; */
  }
  .upload-link {
    padding: 0.25rem;
  }
}
