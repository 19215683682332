.successs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-top: 1.2rem;
  padding: 2rem;
}

.thank-you-message {
  font-family: "Martel", serif;
  color: black;
  /* margin-left: 1.2rem; */
  padding: 0.6rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.success-button {
  display: inline-block;
  padding: 2rem;
  margin-top: 1.6rem;
  margin-bottom: 1.6rem;
  box-sizing: border-box;
  border: 1px solid rgb(187, 237, 180);
  border-radius: 0.3rem;
  /* transition: all 0.2s ease-in-out; */
  font-weight: 600;
  background-color: rgba(78, 119, 74, 0.1);
  max-width: 90%;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
}

.success-button:hover {
  background-color: rgb(22, 118, 22);
  border: 3px solid rgb(135, 249, 78);
  box-shadow: 0px 15px 30px rgba(2, 43, 3, 0.6);
}
