.preset-controls-container {
  /* background-color: rgba(242, 202, 170, 0.94); */
  width: 50vw;
  /* display: flex;
    justify-content: flex-start;
    align-items: center; */
  /* width: 100vw; */
  /* padding: 0.4rem; */
  padding-left: 2rem;
  box-sizing: border-box;
  margin: 0 auto;
  /* margin-right: 4rem; */
}

.style-text-block {
  width: 6rem;
  background-color: transparent;
  /* height: 4rem; */

  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: none;
  color: black;
  font-weight: 600;
  font-size: 0.5rem;
  text-transform: uppercase;
  /* box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease 0s;
}

.preset-button {
  width: 5rem;
  margin: 0.25rem;
  /* height: 4rem; */
  margin-top: 0;
  /* margin-left: 0.25rem; */
  box-sizing: border-box;
  padding: 0.5rem;
  border-radius: 0.3rem;
  border: 2px solid rgb(62, 45, 10);
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 0.5rem;
  text-transform: uppercase;
  box-shadow: 0px 8px 30px rgba(173, 177, 169, 0.1);
  cursor: pointer;
  outline: none;
  transition: all 0.1s ease 0s;
}

.decay-color {
  background-color: #7841c4;
}

.percussive-color {
  background-color: #ac3dbd;
}

.mellow-color {
  background-color: #2815a2;
}
.lively-color {
  background-color: #15a299;
}

.deselected-color {
  background-color: #acb1ac;
}

/* .deselected-textual-green {
  }
  .deselected-hand-green {
    background-color: #acb1ac;
  }
  .deselected-original-green {
    background-color: #8c9d8e;
  } */
